import { createFetch } from '@vueuse/core'
import useWorkspaceStore from '@/stores/workspaceStore'

const useFetch = createFetch({
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  options: {
    async beforeFetch ({ options }) {
      const workspaceStore = useWorkspaceStore()
      const subDomain = workspaceStore.getSubDomain
      const token = String(localStorage.getItem('justoken'))

      options.headers = {
        accept: 'application/json;charset=UTF-8, text/plain, */*',
        Authorization: token,
        Workspace: subDomain,
        ...options.headers
      }

      return { options }
    }
  }
})

export default useFetch
