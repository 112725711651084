import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds, format } from 'date-fns'

export const getLastInteraction = (lastinteractiondate: string | null) => {
  if (lastinteractiondate == null || lastinteractiondate === '') return ''

  const now = new Date()
  const date = new Date(lastinteractiondate)

  if (differenceInSeconds(now, date) < 0) { // diff entre now e date em segundos
    if (differenceInDays(date, now) > 0) { // diff entre date e now em dias
      return format(date, 'dd/MM/yyyy')
    } else {
      return ''
    }
  } else if (differenceInSeconds(now, date) <= 59) {
    return 'há ' + differenceInSeconds(now, date) + ' segundos'
  } else if (differenceInMinutes(now, date) <= 59) {
    return 'há ' + differenceInMinutes(now, date) + ' minuto(s)'
  } else if (differenceInHours(now, date) < 24) {
    return 'há ' + differenceInHours(now, date) + ' hora(s)'
  } else if (differenceInHours(now, date) < 48) {
    return 'há 1 dia'
  } else {
    return format(date, 'dd/MM/yyyy')
  }
}
