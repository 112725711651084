function currency (value: number): string {
  const number = value
  console.log(number)
  if (isNaN(number)) {
    return 'R$ '
  }

  const formattedNumber =
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(number)

  return formattedNumber
}

export default currency
