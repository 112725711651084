import { ref, computed, ComputedRef } from 'vue'
import { defineStore } from 'pinia'
import type {
  Workspace,
  Properties
} from './models'

import * as api from './integrations'
import { isJusttoUser } from '@/utils'

const useWorkspaceStore = defineStore('workspaceStore', () => {
  const workspaceId = Number(sessionStorage.getItem('jusworkspace'))
  const workspaceData = ref(JSON.parse(localStorage.getItem(`jusworkspace_${workspaceId}`) ?? '{}'))

  const getLogoUrl = computed((): string => workspaceData.value.logoUrl)
  const getTeamName = computed((): string => workspaceData.value.teamName)
  const getSubDomain = computed((): string => workspaceData.value.subDomain)
  const getWorkspaceName = computed((): string => workspaceData.value.name)
  const getWorkspaceTeamName = computed((): string => workspaceData.value.teamName)
  const getWorkspaceId = computed((): number => workspaceData.value.id)
  const getProperties = computed((): Record<string, string> => workspaceData.value?.properties ?? {})
  const getUseIntegrationBff = computed((): boolean => workspaceData.value.properties?.USE_INTEGRATION_BFF !== 'DISABLED')
  const getWorkspaceType = computed((): string => {
    return {
      RECOVERY: 'RECOVERY',
      PROCURADORIA: 'PROCURADORIA',
      PROCON_CONSUMIDOR: 'PROCON_CONSUMIDOR'
    }[getProperties.value.NEGOTIATION_TYPE] ?? 'INDENIZATORIO'
  })
  const isWorkspaceRecovery: ComputedRef<boolean> = computed((): boolean => getWorkspaceType.value === 'RECOVERY')
  const isWorkspaceProcuradoria: ComputedRef<boolean> = computed((): boolean => getWorkspaceType.value === 'PROCURADORIA')
  const isWorkspaceRecoveryOrProcuradoria: ComputedRef<boolean> = computed((): boolean => isWorkspaceRecovery.value || isWorkspaceProcuradoria.value)
  const isSudoAdmin: ComputedRef<boolean> = computed((): boolean => isJusttoUser(window.localStorage.getItem('loggedEmail') ?? ''))
  const getRequireDraftObservers = computed((): boolean => workspaceData.value.properties?.REQUIRED_DRAFT_OBSERVERS !== 'DISABLED')
  const useGoogleDocs = computed((): boolean => workspaceData.value.properties?.USE_GOOGLE_DOCS !== 'DISABLED')
  const useOwnEditor = computed((): 'ENABLED' | 'DISABLED' => useGoogleDocs.value ? 'DISABLED' : 'ENABLED')

  function syncWorkspaceData (workspace: Workspace): void {
    workspaceData.value = workspace
    window.localStorage.setItem(`jusworkspace_${workspaceId}`, JSON.stringify(workspace))
  }

  async function updateTeamName (teamName: string): Promise<void> {
    await api.updateTeamName({
      teamName,
      id: getWorkspaceId.value
    })

    syncWorkspaceData({
      ...workspaceData.value,
      teamName
    })
  }

  async function changeWorkspace (workspaceName: string): Promise<void> {
    const body = {
      id: getWorkspaceId.value,
      teamName: getTeamName.value,
      name: workspaceName
    }
    const workspace = await api.changeWorkspace(body)

    syncWorkspaceData(workspace.value)
  }

  async function changeProperties (properties: Properties): Promise<void> {
    const body = {
      workspaceId: getWorkspaceId.value,
      properties
    }

    const workspace = await api.changeProperties(body)

    syncWorkspaceData(workspace.value)
  }

  async function searchWorkspacesCount (): Promise<number> {
    const query = { workspaceId: getWorkspaceId.value }
    const data = await api.searchWorkspacesCount(query)
    return data.value
  }

  return {
    getLogoUrl,
    getTeamName,
    getWorkspaceId,
    getSubDomain,
    getWorkspaceName,
    getWorkspaceTeamName,
    getProperties,
    getUseIntegrationBff,
    getWorkspaceType,
    getRequireDraftObservers,
    isWorkspaceRecovery,
    isWorkspaceProcuradoria,
    isWorkspaceRecoveryOrProcuradoria,
    isSudoAdmin,
    useGoogleDocs,
    useOwnEditor,
    updateTeamName,
    changeWorkspace,
    changeProperties,
    searchWorkspacesCount
  }
})

export default useWorkspaceStore
export { Workspace }
