import useFetch from '@/utils/fetch'
import type { Ref } from 'vue'
import type { Dispute } from './models'

const disputesPath = 'api/disputes'

async function searchDisputesByTerm (query: string): Promise<Ref<Dispute[]>> {
  const response = await useFetch(`${disputesPath}/search?${query}`).get().json()

  return response.data
}

export {
  searchDisputesByTerm
}
