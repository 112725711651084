import type { DisputeRole, Party, Role } from '@/stores/disputesStore/models'

export const getRoles = (disputeRoles: DisputeRole[] | undefined, party: Party, role: Role) => {
  let roles: DisputeRole[] = []

  if (party) {
    if (Array.isArray(party)) roles = (disputeRoles ?? []).filter(disputeRole => party.includes(disputeRole.party))
    else roles = (disputeRoles ?? []).filter(disputeRole => disputeRole.party === party)
  }

  if (role.length > 0) {
    roles = roles.filter(disputeRole => disputeRole.roles.includes(role))
  }

  return roles
}
