import useFetch from '@/utils/fetch'
import { Ref } from 'vue'
import { buildQuery } from '@/utils'
import type {
  WorkspaceBody,
  TeamNameBody,
  PropertiesBody,
  WorkspaceCountQuery,
  Workspace
} from './models'

const consifurationsBffPath = String(process.env.VUE_APP_CONFIGURATIONS_BFF)
const workspacesPath = `${consifurationsBffPath}/workspace`

async function updateTeamName (body: TeamNameBody): Promise<void> {
  await useFetch(`${workspacesPath}/edit/team-name`).patch(body).json()
}

async function changeWorkspace (body: WorkspaceBody): Promise<Ref<Workspace>> {
  const response = await useFetch(`${workspacesPath}/edit`).put(body).json()

  return response.data
}

async function changeProperties (body: PropertiesBody): Promise<Ref<Workspace>> {
  const response = await useFetch(`${workspacesPath}/properties/edit`).put(body).json()

  return response.data
}

async function searchWorkspacesCount (query: WorkspaceCountQuery): Promise<Ref<number>> {
  const response = await useFetch(`${workspacesPath}/count?${buildQuery(query)}`).json()

  return response.data
}

export {
  updateTeamName,
  changeWorkspace,
  changeProperties,
  searchWorkspacesCount
}
