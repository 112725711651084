import { defineStore } from 'pinia'
import { buildQuery } from '@/utils'
import { type Ref } from 'vue'
import type { Dispute } from './models'
import * as api from './integrations'

const useDisputesStore = defineStore('disputesStore', () => {
  async function searchDisputesByTerm (term: string): Promise<Ref<Dispute[]>> {
    const query = buildQuery({ term })
    return await api.searchDisputesByTerm(query)
  }

  return {
    searchDisputesByTerm
  }
})

export default useDisputesStore
